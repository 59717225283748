.audit {
  width: 100%;

  &__inner {
    max-width: $container;
    margin: 0 auto;
    padding: 36px 40px 15px 40px;
    display: flex;
    flex-direction: column;

    &--underlined {
      max-width: $container - 80px;
      padding: 36px 0 15px 0;
      border-bottom: 1px solid #d8d8d8;
    }
  }

  &__title {
    color: #212121;
    font-size: 26px;
    line-height: normal;
    font-weight: 400;
    margin: 0;
  }

  &__site-name {
    color: #212121;
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  &__date {
    color: #9b9b9b;
    font-size: 14px;
    font-style: italic;
    line-height: 24px;
    margin: 0 0 0 auto;
    text-align: right;

    span {
      color: #000000;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 5px;
    }
  }

  &--underlined {

  }
}
