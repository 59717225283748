.domain-info {
  width: 100%;
  margin-bottom: 60px;

  &__inner {
    max-width: $container;
    margin: 0 auto;
    padding: 48px 40px;
    list-style: none;
    border: 2px solid rgba(180,180,180, 0.5);
    background-color: #ffffff;
    border-radius: 6px;
  }

  &__row {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      border-top: 1px dashed #979797;
    }
  }

  &__key {
    padding-right: 30px;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    position: relative;
    margin-right: auto;
  }

  &__value {
    color: #434343;
    font-size: 17px;
    font-weight: 700;
    padding-left: 15px;
    background-color: #ffffff;
    position: relative;
  }
}
