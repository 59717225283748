.page-footer {
  width: 100%;
  background-color: #ffffff;

  &__inner {
    max-width: $container;
    margin: 0 auto;
    padding: 27px 0 33px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__sign {
    max-width: 33.333%;
  }

  &__sender {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  &__support {
    color: #1155cc;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }

  th {
    color: #828282;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    padding-right: 5px;
  }
  td {
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    &:first-of-type {
      padding-right: 10px;
    }

    a {
      color: #434343;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
    }
  }

  &__link {
    color: #1155cc;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }

  &__link-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
  }
}
