.details {
  width: 100%;
  padding: 50px 0 60px 0;

  &__inner {
    width: $container - 80px;
    margin: 0 auto;
  }

  &__item {
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-title {
    padding: 0 20px;
    margin: 0 0 18px 0;
    color: #212121;
    font-size: 26px;
    font-weight: 400;
  }

  &__description {
    padding: 18px 20px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  &__description-page {
    color: #212121;
    font-size: 15px;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__description-list {
    padding-left: 30px;
    color: #7c7c7c;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }

  &__description-item {
    padding-left: 20px;

    a {
      text-decoration: none;
      color: #2282f2;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__robots-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    &:last-of-type {
      margin: 0;
    }
  }

  &__robots-item {
    color: #212121;
    font-size: 15px;
    line-height: normal;
    font-weight: 400;
  }
}
