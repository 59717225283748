.goals {
  width: 100%;
  margin-bottom: 60px;

  &__inner {
    width: $container;
    margin: 0 auto;
    padding: 0 40px;
    list-style: none;

    li {
      margin-bottom: 6px;
    }
  }

  &__item {
    padding: 16px 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    &--success:hover {
      border: 2px solid rgba(0, 171, 59, 0.4);
      padding: 14px 18px;
    }

    &--warning:hover {
      border: 2px solid rgba(245, 166, 35, 0.4);
      padding: 14px 18px;
    }

    &--error:hover {
      border: 2px solid rgba(219, 23, 23, 0.4);
      padding: 14px 18px;
    }

    &--success:hover,
    &--success:focus,
    &--error:hover,
    &--error:focus,
    &--warning:hover,
    &--warning:focus {
      .btn--more {
        background-color: #2182f2;
        border-color: #2182f2;
        color: #fff;
      }
    }

    &--success:active,
    &--error:active,
    &--warning:active {
      .btn--more {
        color: #ffffff;
        border-color: #1168ce;
        background-color: #1168ce;
      }
    }
  }

  &__item-icon {
    width: 26px;
    height: 26px;
    margin-right: 26px;
  }

  &__item-title {
    margin: 0;
    color: #212121;
    font-size: 15px;
    font-weight: 400;
    max-width: 60%;
  }

  &__item-counter {
    margin: 0 0 0 auto;
    color: #434343;
    font-size: 13px;
    font-weight: 700;
  }

  &__item-result {
    margin: 0 0 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #00ae5b;
  }
}
