body {
  padding: 0;
  margin: 0;
  background-color: #efefef;
}

* {
  box-sizing: border-box;
  font-family: $f-family;
}
