.page-header {
  width: 100%;
  background-color: #ffffff;

  &__inner {
    max-width: 820px;
    margin: 0 auto;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: block;

    @media print {
      margin: auto;
    }
  }
}
