.btn {
  display: inline-block;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &--callback {
    padding: 6px 26px;
    color: #212121;
    font-family: $f-family;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid #00ae5b;

    &:hover,
    &:focus {
      color: #fff;
      background-color: #00ae5b;
    }

    &:active {
      color: #fff;
      border: 1px solid #00ae5b;
      background-color: rgba(0, 174, 91, 0.8);
    }

    @media print {
      display: none;
    }
  }

  &--more {
    border-radius: 3px;
    border: 1px solid #abcef8;
    background-color: #ffffff;
    padding: 3px 8px 5px 11px;
    color: #212121;
    font-size: 13px;
    line-height: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-decoration: none;
    margin-left: 20px;
  }
  &--more-arrow {
    transform: rotate(-90deg);
    margin-left: 5px;
  }

  &--back {
    position: fixed;
    bottom: 85px;
    right: 10%;
    color: #212121;
    padding: 7px 69px 10px 19px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    border-radius: 24px;
    border: 1px solid #00ae5b;
    text-decoration: none;

    svg {
      transform: rotate(90deg);
      margin-right: 30px;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-color: #00ae5b;
    }

    &:active {
      color: #fff;
      border: 1px solid #00ae5b;
      background-color: rgba(0, 174, 91, 0.8);
    }

    @media print {
      display: none;
    }
  }

  &--print {
    position: fixed;
    bottom: 35px;
    right: 10%;
    border: none;
    border-radius: 24px;
    background-color: #00ae5b;
    padding: 7px 34px 5px 17px;
    font-size: 0;
    color: #ffffff;

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }

    &:hover,
    &:focus {
      background-color: rgba(0, 174, 91, 0.8);
    }

    @media print {
      display: none;
    }
  }
}
